<template>
  <div :class="{ fixed: headerFixed }">
    <div ref="header" class="header" :class="headerFixed ? 'isFixed' : ''">
      <div class="header_box">
        <div class="logo_box">
          <img class="logo" src="@/static/images/header_logo.png" alt="" />
        </div>
        <div class="menu_box">
          <div
            class="menu_item"
            :class="{ active_menu: isActive(menu) }"
            v-for="menu in menus"
            :key="menu.id"
            @click="changeMenu(menu)"
          >
            {{ menu.columnName }}
            <div class="submenu" v-if="menu.columnChild.length">
              <div
                class="submenu_item"
                :class="{ active_submenu: activeMenuId == submenu.id }"
                v-for="submenu in menu.columnChild"
                :key="submenu.id"
                @click.stop="changeMenu(menu, submenu)"
              >
                {{ submenu.columnName }}
              </div>
            </div>
          </div>
        </div>
        <div class="donate_button" @click="openUrl">
          <span>我要捐赠</span>
          <i class="el-icon-right"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { queryColumnList } from "@/api/common";
export default {
  name: "HeaderModule",
  data() {
    return {
      activeMenuId: null,
      menus: [],
      offsetTop: 0,
      offsetHeight: 0,
      headerFixed: 0,
    };
  },
  created() {
    this.getMenu();
    this.$nextTick(() => {
      let header = this.$refs.header;
      this.offsetTop = header.offsetTop;
      this.offsetHeight = header.offsetHeight;
      // 监听滚动条
      window.addEventListener("scroll", this.handleScroll);
    });
  },
  watch: {
    $route: {
      handler(newValue) {
        if (newValue) {
          if (newValue.query.activeMenuId) {
            this.headerFixed = 0;
            this.activeMenuId = newValue.query.activeMenuId;
          } else if (newValue.path == "/") {
            let menus = JSON.parse(localStorage.getItem("menus"));
            this.activeMenuId = menus[0].id;
          }
        }
      },
    },
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    // 获取菜单
    getMenu() {
      queryColumnList().then((res) => {
        if (res.status != 200) return;
        this.menus = res.data;
        localStorage.setItem("menus", JSON.stringify(this.menus));
        this.activeMenuId = this.activeMenuId
          ? this.activeMenuId
          : this.menus[0].id;
      });
    },
    // 选中的菜单
    isActive(menu) {
      if (menu.id == this.activeMenuId) return true;
      return menu.columnChild.some((item) => item.id == this.activeMenuId);
    },
    // 切换菜单
    changeMenu(menu, submenu = false) {
      if (
        (this.activeMenuId == menu.id ||
          (submenu && this.activeMenuId == submenu.id) ||
          (menu.columnChild.length && !submenu)) &&
        this.$route.name != "ProjectDetails"
      ) {
        return;
      }
      if (submenu) {
        this.activeMenuId = submenu.id;
      } else {
        this.activeMenuId = menu.id;
      }
      if (this.activeMenuId == 104) {
        this.$router.push({ path: "/" });
        return;
      } else if (this.activeMenuId == 152) {
        // this.$router.push({
        //   path: "/registerVolunteer",
        //   query: { activeMenuId: this.activeMenuId },
        // });
        window.open("https://ff.lingxi360.com/f?fid=OLcr7aaEs&utm_bccid=LXE5SzPT");
        return;
      }
      this.$router.push({
        path: "/content",
        query: { activeMenuId: submenu.id },
      });
    },
    // 我要捐赠
    openUrl() {
      window.open("http://www.cmjjh.cn/content?activeMenuId=122&activeSunId=945&columnType=106&columnTypeBlen=true");
    },
    // 是否吸顶
    handleScroll() {
      // 得到页面滚动的距离，兼容写法
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // 判断页面滚动的距离是否大于吸顶元素的位置
      if (scrollTop == 0 || scrollTop < this.offsetHeight) return (this.headerFixed = false);
      this.headerFixed = scrollTop > this.offsetTop - this.offsetHeight;
    },
  },
};
</script>

<style lang="scss" scoped>
.fixed {
  padding-bottom: 107px;
}
.header{
  background: #fff;
}
.header_box {
  display: flex;
  align-items: center;
  height: 80px;
  margin: 0 auto;
  width: 1400px;
  background: #fff;
  .logo_box,
  .logo {
    width: 267px;
    height: 44px;
  }
  .menu_box {
    display: flex;
    margin-left: auto;
    height: 100%;
    font-size: 20px;
    cursor: pointer;
    // &:hover {
    //   .menu_item {
    //     color: #333;
    //     border-bottom: 4px solid transparent;
    //   }
    // }
    .menu_item {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 130px;
      height: 100%;
      text-align: center;
      box-sizing: border-box;
      border-bottom: 4px solid transparent;
      &:hover {
        color: #43b265;
        border-bottom: 4px solid #43b265;
        .submenu {
          display: block;
        }
      }
    }
    .active_menu {
      font-weight: 600;
      color: #43b265;
      border-bottom: 4px solid #43b265;
    }
    .submenu {
      display: none;
      position: absolute;
      z-index: 99;
      top: calc(100% + 4px);
      padding-bottom: 16px;
      width: 100%;
      background: #fff;
      .submenu_item {
        padding-top: 16px;
        font-size: 17px;
        font-weight: 400;
        color: #333333;
        &:hover {
          font-weight: 600;
          color: #43b265;
        }
      }
      .active_submenu {
        font-weight: 600;
        color: #43b265;
      }
    }
  }
  .donate_button {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 8px;
    margin-left: 14px;
    width: 126px;
    height: 36px;
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
    line-height: 36px;
    background: #ff8b38;
    box-shadow: 0px 6px 18px 0px rgba(162, 75, 11, 0.16);
    border-radius: 18px;
    i {
      font-size: 20px;
      margin-left: 10px;
    }
  }
}
.isFixed {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 999;
}
</style>
