
export default {
  // yyyy-MM-dd
  dateFormat(time) {
    var t = new Date(time);
    var month = t.getMonth() + 1;
    month = month < 10 ? '0' + month : month;
    var day = t.getDate();
    day = day < 10 ? '0' + day : day;
    return t.getFullYear() + "-" + month + "-" + day
  },
  getTime(time) {
    var date = new Date(time.replace(/-/g, "/"));
    var y = date.getFullYear();
    var m =
      date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1;
    var d = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    return y + "-" + m + "-" + d;
  },
  getTimeDot(time) {
    var date = new Date(time.replace(/-/g, "/"));
    var y = date.getFullYear();
    var m =
      date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1;
    var d = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    return y + "." + m + "." + d;
  },
};
