import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

// const VueRouterPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(to) {
//   return VueRouterPush.call(this, to).catch(err => err)
// }

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/home"),
  },
  {
    path: "/content",
    name: "content",
    component: () =>
      import("../views/content.vue"),
  },
  {
    path: "/projectDetails",
    name: "ProjectDetails",
    component: () =>
      import("../views/projectDetails.vue"),
  },
  {
    path: "/contentDetails",
    name: "ContentDetails",
    component: () =>
      import("../views/contentDetails.vue"),
  },
  {
    path: "/registerVolunteer",
    name: "RegisterVolunteer",
    component: () =>
      import("../views/registerVolunteer.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  // document.documentElement.scrollTop = 0;
  // document.body.scrollTop = 0;
  next();
});

export default router;
