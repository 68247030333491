var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"footer_box"},[_c('div',{staticClass:"footer_top"},[_vm._m(0),_c('ul',{staticClass:"content"},[_c('li',[_c('span',{staticClass:"label"},[_vm._v("关于春苗：")]),_vm._l((_vm.menus),function(item){return _c('span',{key:item.id,staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.navigateTo(item)}}},[_vm._v(_vm._s(item.name))])})],2),_vm._m(1),_vm._m(2),_vm._m(3)]),_vm._m(4)])]),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo_box"},[_c('img',{staticClass:"logo",attrs:{"src":require("@/static/images/footer_logo.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('span',{staticClass:"label"},[_vm._v("联系电话：")]),_c('span',[_vm._v("400-668-6518")]),_c('span',[_vm._v("09:00-18:00")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('span',{staticClass:"label"},[_vm._v("邮箱：")]),_c('span',[_vm._v("chunmiaojjh@cmjjh.org")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('span',{staticClass:"label"},[_vm._v("地址：")]),_c('span',[_vm._v("北京市东城区朝阳门SOHO 1111")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"qr_code"},[_c('li',[_c('img',{attrs:{"src":require("@/static/images/wx_qr_code.png"),"alt":""}}),_c('p',[_vm._v("春苗公众号")])]),_c('li',[_c('img',{attrs:{"src":require("@/static/images/wb_qr_code.png"),"alt":""}}),_c('p',[_vm._v("春苗微博")])]),_c('li',[_c('img',{attrs:{"src":require("@/static/images/dy_qr_code.png"),"alt":""}}),_c('p',[_vm._v("春苗抖音号")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"aq"},[_c('a',{attrs:{"href":"https://beian.miit.gov.cn/","target":"_blank"}},[_vm._v("Copyright ©2023 北京春苗慈善基金会 京ICP备11005547号-2")])])
}]

export { render, staticRenderFns }