<template>
  <div
    v-if="activeMenuId == 'home'"
    class="swiper"
  >
    <el-carousel
      ref="swiper"
      :interval="5000"
      arrow="vertical"
    >
      <el-carousel-item v-for="item in banners" :key="item.id">
        <img
          draggable="false"
          :src="item.picture"
          alt=""
          @click="openUrl(item.link)"
        />
      </el-carousel-item>
    </el-carousel>
  </div>
  <div v-else class="banner">
    <img
      v-if="banners.length"
      draggable="false"
      :src="banners[0].picture"
      alt=""
    />
  </div>
</template>

<script>
import { Carousel, CarouselItem } from "element-ui";
import { queryBannerList, queryColumnIdBanner } from "@/api/common";
export default {
  name: "HeaderModule",
  components: {
    [Carousel.name]: Carousel,
    [CarouselItem.name]: CarouselItem,
  },
  data() {
    return {
      banners: [],
      mouseDown: false,
      downX: null,
      activeMenuId: null,
    };
  },
  watch: {
    $route: {
      handler(newValue) {
        if (newValue) {
          if (newValue.query.activeMenuId) {
            let menus = JSON.parse(localStorage.getItem("menus"));
            let subMenus = menus.find((item) =>
              item.columnChild.find(
                (subItem) => subItem.id == newValue.query.activeMenuId
              )
            );
            this.activeMenuId = subMenus.id;
            this.getRestBanner();
          } else if (newValue.name == "home") {
            this.activeMenuId = newValue.name;
            this.getBanner();
          }
        }
      },
    },
  },
  created() {},
  methods: {
    // 获取banner
    getBanner() {
      queryBannerList({ type: 1 }).then((res) => {
        if (res.status != 200) return;
        this.banners = res.data;
      });
    },
    // 获取其他banner
    getRestBanner() {
      queryColumnIdBanner({ columnId: this.activeMenuId }).then((res) => {
        if (res.status != 200) return;
        this.banners = res.data;
      });
    },
    mousedown(e) {
      this.mouseDown = true;
      this.downX = e.clientX ? e.clientX : e.changedTouches[0].clientX;
    },
    mouseleave() {
      this.downX = null;
      this.mouseDown = false;
    },
    mouseup(e) {
      let downX = e.clientX ? e.clientX : e.changedTouches[0].clientX;
      let difference =
        downX < this.downX ? this.downX - downX : downX - this.downX;
      if (!this.mouseDown || difference < 50) return;
      this.mouseDown = true;
      if (downX < this.downX) {
        this.$refs.swiper.next();
      } else {
        this.$refs.swiper.prev();
      }
      this.downX = null;
      this.mouseDown = false;
    },
    openUrl(url) {
      window.open(url);
    },
  },
};
</script>

<style lang="scss" scoped>
.swiper {
  ::v-deep .el-carousel__container {
    width: 100%;
    height: 540px;
    img {
      width: 100%;
      height: 540px;
    }
    .el-carousel__arrow--left {
      left: 0;
    }
    .el-carousel__arrow--right {
      right: 0;
    }
    .el-carousel__arrow {
      width: 60px;
      height: 98px;
      background-color: rgba(31, 45, 61, 0.3);
      border-radius: 0;
      i {
        font-size: 34px;
      }
    }
  }
}
.banner {
  width: 100%;
  height: 540px;
  img {
    width: 100%;
    height: 540px;
  }
}
</style>
