<template>
  <div>
    <div class="footer_box">
      <div class="footer_top">
        <div class="logo_box">
          <img class="logo" src="@/static/images/footer_logo.png" alt="" />
        </div>
        <ul class="content">
          <li>
            <span class="label">关于春苗：</span>
            <span
              v-for="item in menus"
              :key="item.id"
              style="cursor: pointer"
              @click="navigateTo(item)"
              >{{ item.name }}</span
            >
          </li>
          <li>
            <span class="label">联系电话：</span>
            <span>400-668-6518</span>
            <span>09:00-18:00</span>
          </li>
          <li>
            <span class="label">邮箱：</span>
            <span>chunmiaojjh@cmjjh.org</span>
          </li>
          <li>
            <span class="label">地址：</span>
            <span>北京市东城区朝阳门SOHO 1111</span>
          </li>
        </ul>
        <ul class="qr_code">
          <li>
            <img src="@/static/images/wx_qr_code.png" alt="" />
            <p>春苗公众号</p>
          </li>
          <li>
            <img src="@/static/images/wb_qr_code.png" alt="" />
            <p>春苗微博</p>
          </li>
          <li>
            <img src="@/static/images/dy_qr_code.png" alt="" />
            <p>春苗抖音号</p>
          </li>
        </ul>
      </div>
    </div>
    <div class="aq">
      <a href="https://beian.miit.gov.cn/" target="_blank"
        >Copyright ©2023 北京春苗慈善基金会 京ICP备11005547号-2</a
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterModule",
  data() {
    return {
      menus: [
        {
          name: "春苗简介",
          id: "106",
        },
        {
          name: "组织架构",
          id: "107",
        },
        {
          name: "理事会",
          id: "108",
        },
        {
          name: "执行团队",
          id: "109",
        },
        {
          name: "社会影响",
          id: "110",
        },
        {
          name: "合作伙伴",
          id: "121",
        },
        {
          name: "联系春苗",
          id: "122",
        },
      ],
    };
  },
  created() {},
  methods: {
    navigateTo(item) {
      this.$router.push({
        path: "/content",
        query: {
          activeMenuId: item.id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.footer_box {
  background: #43b265;
  .footer_top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // padding: 0 460px;
    margin: 0 auto;
    width: 1400px;
    height: 200px;
    background: #43b265;

    .logo_box {
      width: 102px;
      .logo {
        width: 102px;
        height: 98px;
      }
    }
    .content {
      font-size: 16px;
      font-weight: 400;
      text-align: center;
      color: #ffffff;
      li {
        &:first-child {
          margin-top: 0;
        }
        margin-top: 10px;
      }
      span {
        &:first-child {
          margin-right: 0;
        }
        &:last-child {
          margin-right: 0;
        }
        margin-right: 16px;
      }
    }
    .qr_code {
      display: flex;
      li {
        &:first-child {
          margin-left: 0;
        }
        margin-left: 10px;
        img {
          width: 60px;
          height: 60px;
        }
        p {
          margin-top: 6px;
          font-size: 12px;
          font-weight: 400;
          color: #ffffff;
          text-align: center;
        }
      }
    }
  }
}
.aq {
  height: 49px;
  line-height: 49px;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  text-align: center;
}
</style>
