import request from "@/utils/request";

// 获取栏目列表
export function queryColumnList(params) {
  return request({
    url: "/reception/queryColumnList",
    method: "get",
    params,
  });
}

// 获取首页/其他banner列表
export function queryBannerList(params) {
  return request({
    url: "/reception/queryBannerList",
    method: "get",
    params,
  });
}

// 根据栏目id获取Banner
export function queryColumnIdBanner(params) {
  return request({
    url: "/reception/queryColumnIdBanner",
    method: "get",
    params,
  });
}


//根据字典类型获取对应字典数据 查询多个字典
export function queryTypeDictList(data) {
  return request({
    url: "/reception/queryTypeDictList", //接口地址
    method: "post", //请求方式
    data, //参数
  });
}

//新增志愿者
export function saveCmvolunteer(data) {
  return request({
    url: "/reception/saveCmvolunteer", //接口地址
    method: "post", //请求方式
    data, //参数
  });
}
