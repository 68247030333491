import axios from "axios";
import router from "@/router/index";
// import { getToken } from "@/utils/auth";
// create an axios instance
const service = axios.create({
  // baseURL: "http://192.168.1.156:8023",
  // baseURL: "http://www.cmjjh.cn/api",
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 10000, // request timeout
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    // do something before request is sent

    // if (getToken()) {
    // let each request carry token
    // ['X-Token'] is a custom headers key
    // please modify it according to the actual situation
    config.headers["X-Requested-With"] = "XMLHttpRequest";
    let commonWord = document.getElementById("commonWord") || {};
    config.headers["User-Defined"] = commonWord;
    // }
    // 转换参数
    if (config.isJson) {
      config.headers["Content-Type"] =
        "application/x-www-form-urlencoded;charset=utf-8";
      let data = new URLSearchParams();
      Object.keys(config.data).forEach((key) => {
        if (config.data[key] != "" && config.data[key] != null) {
          data.append(key, config.data[key]);
        }
      });
      config.data = data;
    }
    return config;
  },
  (error) => {
    // do something with request error
    console.log(error); // for debug
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  (response) => {
    const res = response.data;
    return res;
  },
  (error) => {
    console.log("err" + error); // for debug
    return Promise.reject(error);
  }
);

export default service;
